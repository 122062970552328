const taxa1 = require("../utils/constants.js").default.SIMULADOR_TAXA1
const taxa2 = require("../utils/constants.js").default.SIMULADOR_TAXA2

export default {
    getFator(gender, incomeTax, age) {
        const data = require("../utils/simulatorValues.js")
        let fator;

        if (gender === "female")
            fator = data.default.fator_feminino[incomeTax === "vgbl" ? 0 : 1][age].fator
        else
            fator = data.default.fator_masculino[incomeTax === "vgbl" ? 0 : 1][age].fator

        return fator
    },

    getMeses(retirementAge, currentAge) {
        return (retirementAge == currentAge ? 1 : (retirementAge - currentAge)) * 12
    },

    calculaJurosMensais(curJurosGarantido, curProjecaoJuros) {
        let curJurosAno = (((parseFloat(curJurosGarantido) / 100) + 1) * ((parseFloat(curProjecaoJuros) / 100) + 1) - 1) * 100;
        let TaxaJurosMes = parseFloat(parseFloat((((parseFloat(curJurosAno) / 100) + 1) ** 0.083333333 - 1)) * 100);

        return TaxaJurosMes;
    },

    //Calculo de valor futuro, igual Excel
    FV(rate, nper, pmt, pv, type) {
        var pow = Math.pow(1 + rate, nper),
            fv;
        if (rate) {
            fv = (pmt * (1 + rate * type) * (1 - pow) / rate) - pv * pow;
        } else {
            fv = -1 * (pv + pmt * nper);
        }
        return fv.toFixed(2);
    },

    getValuesFromMonthlyContribution(
        monthlyContribution,
        fator,
        incomeTax,
        retirementAge,
        currentAge,
        inflation) {

        if (incomeTax === "pgbl")
            monthlyContribution = monthlyContribution * 0.95;

        let diferimentoMeses = (retirementAge == currentAge ? 1 : (retirementAge - currentAge)) * 12;
        let taxaJurosMes = this.calculaJurosMensais(0, inflation);
        let reservaFutura = -this.FV(taxaJurosMes / 100, diferimentoMeses, monthlyContribution, 0, 1);

        let valorBeneficio = reservaFutura / (fator * 12);

        return [valorBeneficio, reservaFutura];
    },

    getValuesFromLifetimeMonthlyIncome(lifeTimeMonthlyIncome, meses, fator) {
        let ct = Math.trunc(lifeTimeMonthlyIncome / fator)

        let mc = Math.trunc(ct /
            ([(Math.pow(1 + (Math.pow(((1 + taxa1) * (1 + taxa2)), (1 / 12)) - 1), meses)) - 1] /
                (Math.pow(((1 + taxa1) * (1 + taxa2)), (1 / 12)) - 1)))

        return [mc, ct]
    },

    getValuesFromCumulativeTotal(cumulativeTotal, meses, fator) {
        let lmi = Math.trunc(cumulativeTotal * fator)

        let mc = Math.trunc(cumulativeTotal /
            ([(Math.pow(1 + (Math.pow(((1 + taxa1) * (1 + taxa2)), (1 / 12)) - 1), meses)) - 1] /
                (Math.pow(((1 + taxa1) * (1 + taxa2)), (1 / 12)) - 1)))

        return [mc, lmi]
    },

    getOnlyNumbers(v) {
        return v.replace(/[\D]+/g, '')
    },

    setPhoneMask(v) {
        v = v.replace(/\D/g, "")
        v = v.replace(/^(\d\d)(\d)/g, "($1) $2")

        if (v.length === 14)
            v = v.replace(/(\d{5})(\d)/, "$1-$2")
        else
            v = v.replace(/(\d{4})(\d)/, "$1-$2")

        return v
    },

    setCpfMask(v) {
        v = v.replace(/\D/g, "")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

        return v
    },

    setMoneyWithDecimalMask(v) {
        v = v + ''

        if (v.length === 1)
            v = v.replace(/([0-9]{1})$/g, "0,0$1")
        else if (v.length === 2)
            v = v.replace(/([0-9]{2})$/g, "0,$1")
        else
            v = v.replace(/([0-9]{2})$/g, ",$1")

        if (v.length > 6)
            v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

        return v
    },

    setMoneyMask(v) {
        v = v + '';
        v = parseInt(v.replace(/[\D]+/g, ''));
        v = v + '';

        while (v.match(/^\d\d{3}/)) {
            v = v.replace(/(\d)(\d{3}(\.|,|$))/, '$1.$2');
        }

        return v
    },

    setMoneyFormat(v) {
        return v.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },

    setDecimalFormat(v, digits) {
        return parseFloat(v).toFixed(digits)
    },

    getAporteTableValues(annualGrossIncome, dependentsNumber, inssContributions, educationExpenses, medicalExpenses, supplementaryPensionPayment) {
        let receitaComAporte = this.setMoneyFormat(annualGrossIncome)
        let receitaSemAporte = this.setMoneyFormat(annualGrossIncome)

        let aliquotaInss,
            inssComAporte, inssSemAporte,
            dependentesComAporte, dependentesSemAporte,
            educacaoComAporte, educacaoSemAporte,
            medicoComAporte, medicoSemAporte,
            aspecirLuterprevComAporte, aspecirLuterprevSemAporte,
            baseAnoComAporte, baseAnoSemAporte,
            baseCalculoAnoComAporte, baseCalculoAnoSemAporte,
            baseMesComAporte, baseMesSemAporte,
            baseCalculoMesComAporte, baseCalculoMesSemAporte,
            aliquotaComAporte, aliquotaSemAporte,
            parcelaDeduzirComAporte, parcelaDeduzirSemAporte,
            baseXAliquotaComAporte, baseXAliquotaSemAporte,
            impostoDevidoComAporte, impostoDevidoSemAporte,
            aporteFinal,
            beneficioComAporteFinal = 0

        // renda bruta mensal
        let rendaBrutaMensal = annualGrossIncome / 12

        // alíquota do INSS
        if (rendaBrutaMensal < 1659.38)
            aliquotaInss = 0.08
        else if (rendaBrutaMensal < 2765.66)
            aliquotaInss = 0.09
        else if (rendaBrutaMensal < 5531.31)
            aliquotaInss = 0.11
        else
            aliquotaInss = 0.11

        // inss com aporte e sem aporte        
        if ((annualGrossIncome * aliquotaInss) > 7708.08) {
            if (inssContributions > 7708.08) {
                inssComAporte = 7708.08
                inssSemAporte = 7708.08
            } else {
                inssComAporte = inssContributions
                inssSemAporte = inssContributions
            }
        } else {
            if (inssContributions > 7708.08) {
                inssComAporte = 7708.08
                inssSemAporte = 7708.08
            } else {
                inssComAporte = annualGrossIncome * aliquotaInss
                inssSemAporte = annualGrossIncome * aliquotaInss
            }
        }

        // dependentes com aporte e sem aporte
        dependentesComAporte = dependentsNumber * 2275.08
        dependentesSemAporte = dependentsNumber * 2275.08

        // educação com aporte e sem aporte
        if (educationExpenses > 3561.5) {
            educacaoComAporte = 3561.5
            educacaoSemAporte = 3561.5
        } else {
            educacaoComAporte = educationExpenses
            educacaoSemAporte = educationExpenses
        }

        // despesa médica com aporte e sem aporte
        medicoComAporte = medicalExpenses
        medicoSemAporte = medicalExpenses

        // Aspecir/Luterprev
        if (supplementaryPensionPayment > (annualGrossIncome * 0.12)) {
            aspecirLuterprevComAporte = annualGrossIncome * 0.12
            aspecirLuterprevSemAporte = annualGrossIncome * 0.12
        } else {
            aspecirLuterprevComAporte = annualGrossIncome * 0.12
            aspecirLuterprevSemAporte = supplementaryPensionPayment
        }

        // base de cálculo anual
        baseCalculoAnoComAporte = (annualGrossIncome - (inssComAporte + dependentesComAporte + educacaoComAporte + medicoComAporte + aspecirLuterprevComAporte))
        baseCalculoAnoSemAporte = (annualGrossIncome - (inssSemAporte + dependentesSemAporte + educacaoSemAporte + medicoSemAporte + aspecirLuterprevSemAporte))

        baseAnoComAporte = baseCalculoAnoComAporte
        baseAnoSemAporte = baseCalculoAnoSemAporte

        // base de cálculo mensal
        baseCalculoMesComAporte = (annualGrossIncome - (inssComAporte + dependentesComAporte + educacaoComAporte + medicoComAporte + aspecirLuterprevComAporte)) / 12
        baseCalculoMesSemAporte = (annualGrossIncome - (inssSemAporte + dependentesSemAporte + educacaoSemAporte + medicoSemAporte + aspecirLuterprevSemAporte)) / 12

        baseMesComAporte = baseCalculoMesComAporte
        baseMesSemAporte = baseCalculoMesSemAporte

        // base de alíquota e parcela a deduzir com aporte
        if (baseMesComAporte < 1903.98) {
            aliquotaComAporte = 0
            parcelaDeduzirComAporte = 0
        } else if (baseMesComAporte < 2826.65) {
            aliquotaComAporte = 7.5
            parcelaDeduzirComAporte = 1713.60
        } else if (baseMesComAporte < 3751.05) {
            aliquotaComAporte = 15
            parcelaDeduzirComAporte = 4257.60
        } else if (baseMesComAporte < 4664.68) {
            aliquotaComAporte = 22.5
            parcelaDeduzirComAporte = 7633.56
        } else {
            aliquotaComAporte = 27.5
            parcelaDeduzirComAporte = 10432.32
        }

        // base de alíquota e parcela a deduzir sem aporte
        if (baseMesSemAporte < 1903.98) {
            aliquotaSemAporte = 0
            parcelaDeduzirSemAporte = 0
        }
        else if (baseMesSemAporte < 2826.65) {
            aliquotaSemAporte = 7.5
            parcelaDeduzirSemAporte = 1713.60
        }
        else if (baseMesSemAporte < 3751.05) {
            aliquotaSemAporte = 15;
            parcelaDeduzirSemAporte = 4257.60
        }
        else if (baseMesSemAporte < 4664.68) {
            aliquotaSemAporte = 22.5
            parcelaDeduzirSemAporte = 7633.56
        }
        else {
            aliquotaSemAporte = 27.5
            parcelaDeduzirSemAporte = 10432.32
        }

        // base * alíquota
        baseXAliquotaComAporte = baseAnoComAporte * (aliquotaComAporte / 100)
        baseXAliquotaSemAporte = baseAnoSemAporte * (aliquotaSemAporte / 100)

        // imposto devido = (base * alíquota) - parcela a deduzir
        impostoDevidoComAporte = baseXAliquotaComAporte - parcelaDeduzirComAporte
        impostoDevidoSemAporte = baseXAliquotaSemAporte - parcelaDeduzirSemAporte

        // aporte final
        aporteFinal = aspecirLuterprevComAporte - aspecirLuterprevSemAporte

        // benefício com aporte final
        beneficioComAporteFinal = impostoDevidoSemAporte - impostoDevidoComAporte

        return [
            receitaSemAporte, receitaComAporte,
            this.setMoneyFormat(inssSemAporte),
            this.setMoneyFormat(inssComAporte),
            this.setMoneyFormat(dependentesSemAporte),
            this.setMoneyFormat(dependentesComAporte),
            this.setMoneyFormat(educacaoSemAporte),
            this.setMoneyFormat(educacaoComAporte),
            this.setMoneyFormat(medicoSemAporte),
            this.setMoneyFormat(medicoComAporte),
            this.setMoneyFormat(aspecirLuterprevSemAporte),
            this.setMoneyFormat(aspecirLuterprevComAporte),
            this.setMoneyFormat(baseCalculoAnoSemAporte),
            this.setMoneyFormat(baseCalculoAnoComAporte),
            aliquotaSemAporte + "%",
            aliquotaComAporte + "%",
            this.setMoneyFormat(impostoDevidoSemAporte),
            this.setMoneyFormat(impostoDevidoComAporte),
            this.setMoneyFormat(aporteFinal),
            this.setMoneyFormat(beneficioComAporteFinal),
        ]
    }
}
